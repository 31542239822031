import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/Docs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`How to write`}</h2>
    <p>{`Ogilvy & Mather, 1982`}</p>
    <img {...{
      "wide": "true",
      "src": "https://res.cloudinary.com/studiocollective/image/upload/v1714499631/Coaching%20Site/IMG_4205_wlm1y1.jpg"
    }}></img>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      